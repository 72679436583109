import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[ClockLocation]';

export const clockLocationActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,

  CHANGE_RADIUS: `${ACTION_PREFIX} Update Radius`,
  CHANGE_RADIUS_SUCCESS: `${ACTION_PREFIX} Update Radius Success`,
  CHANGE_RADIUS_FAILED: `${ACTION_PREFIX} Update Radius Failed`,

  CHANGE_LOCATION: `${ACTION_PREFIX} Change Location`,
  CHANGE_LOCATION_SUCCESS: `${ACTION_PREFIX} Change Location Success`,
  CHANGE_LOCATION_FAILED: `${ACTION_PREFIX} Change Location Failed`,

  UPDATE_LOCATION: `${ACTION_PREFIX} Update Location`,
  UPDATE_LOCATION_SUCCESS: `${ACTION_PREFIX} Update Location Success`,
  UPDATE_LOCATION_FAILED: `${ACTION_PREFIX} Update Location Failed`,
};

export class ClockLocationAction {
  public static load(): Action {
    return {
      type: clockLocationActionType.LOAD,
    };
  }

  public static loadSuccess(clockLocation): Action {
    return {
      type: clockLocationActionType.LOAD_SUCCESS,
      payload: clockLocation,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: clockLocationActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(clockLocationData): Action {
    return {
      type: clockLocationActionType.ADD,
      payload: clockLocationData,
    };
  }

  public static addSuccess(clockLocationResponse): Action {
    return {
      type: clockLocationActionType.ADD_SUCCESS,
      payload: clockLocationResponse,
    };
  }

  public static addFailed(err): Action {
    return {
      type: clockLocationActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static remove(id): Action {
    return {
      type: clockLocationActionType.REMOVE,
      payload: id,
    };
  }

  public static removeSuccess(clockLocationResponse): Action {
    return {
      type: clockLocationActionType.REMOVE_SUCCESS,
      payload: clockLocationResponse,
    };
  }

  public static removeFailed(err): Action {
    return {
      type: clockLocationActionType.REMOVE_FAILED,
      payload: err,
    };
  }

  public static changeRadius(id, radius): Action {
    return {
      type: clockLocationActionType.CHANGE_RADIUS,
      payload: {
        id: id,
        radius: radius,
      },
    };
  }

  public static changeRadiusSuccess(payload): Action {
    return {
      type: clockLocationActionType.CHANGE_RADIUS_SUCCESS,
      payload: payload,
    };
  }

  public static changeRadiusFailed(payload): Action {
    return {
      type: clockLocationActionType.CHANGE_RADIUS_FAILED,
      payload: payload,
    };
  }

  public static changeLocation(id, lat, lon): Action {
    return {
      type: clockLocationActionType.CHANGE_LOCATION,
      payload: {
        id: id,
        latitude: lat,
        longitude: lon,
      },
    };
  }

  public static changeLocationSuccess(payload): Action {
    return {
      type: clockLocationActionType.CHANGE_LOCATION_SUCCESS,
      payload: payload,
    };
  }

  public static changeLocationFailed(payload): Action {
    return {
      type: clockLocationActionType.CHANGE_LOCATION_FAILED,
      payload: payload,
    };
  }

  public static updateLocation(id, data): Action {
    return {
      type: clockLocationActionType.UPDATE_LOCATION,
      payload: {
        id,
        data,
      },
    };
  }

  public static updateLocationSuccess(payload): Action {
    return {
      type: clockLocationActionType.UPDATE_LOCATION_SUCCESS,
      payload: payload,
    };
  }

  public static updateLocationFailed(payload): Action {
    return {
      type: clockLocationActionType.UPDATE_LOCATION_FAILED,
      payload: payload,
    };
  }
}
